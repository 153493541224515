﻿import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const sections = gsap.utils.toArray("section");
var refreshPriority = sections.length;
sections.forEach(container => {
    if (container.classList.contains("video-overlay")) {
        const bgVideoTl = gsap.timeline();

        bgVideoTl.to(container.querySelectorAll(".background-video"),
            {
                duration: 3,
                height: "100vh",
                width: "100vw"
            });

        bgVideoTl.to(container.querySelectorAll(".statement-animate"),
            {
                duration: 3,
                yPercent: -100
            },
            "-=3");

        bgVideoTl.to(container.querySelectorAll(".bg-wrapper"),
            {
                duration: 3,
                opacity: 0
            },
            "-=2");

        bgVideoTl.to(container.querySelectorAll(".playIcon"),
            {
                duration: 3,
                opacity: 1,
                zIndex: 10
            },
            "-=2");

        ScrollTrigger.create({
            animation: bgVideoTl,
            trigger: container,
            start: "top",
            end: "bottom",
            scrub: 0.25,
            pin: true,
            refreshPriority: refreshPriority
        });
    }
    refreshPriority--;
});


const playIcon = document.getElementsByClassName("playIcon")[0];
const menuTopVideo = document.getElementsByClassName("menu-top-video")[0];

playIcon.addEventListener("touchstart", handlePlayIcon);
playIcon.addEventListener("click", handlePlayIcon);

menuTopVideo.addEventListener("touchstart", handleTopVideo);
menuTopVideo.addEventListener("click", handleTopVideo);


function handlePlayIcon(evt) {
    evt.preventDefault();
    document.body.style.overflow = "hidden";

    const videoModal = document.getElementsByClassName("video-modal")[0];

    videoModal.style.display = "block";
    const video = document.getElementById("bgVideoModal");
    if (video.requestFullscreen) {
        video.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
        video.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
        video.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
        video.msRequestFullscreen();
    } else {
        alert("Full screen not supported");
        return;
    }
    video.play();
}

function handleTopVideo(evt) {
    evt.preventDefault();
    document.body.style.overflow = "inherit";
    const videoModal = document.getElementsByClassName("video-modal")[0];

    videoModal.style.display = "none";
    ScrollTrigger.refresh();
    const video = document.getElementById("bgVideoModal");
    video.pause();
}